import React, { lazy, Suspense, useEffect, useState } from 'react'
import { formatCurrency } from '../../../common/helper-methods'
const Slider = lazy(() => import(/* webpackChunkName: "Slider" */'react-slick'))
const Form = lazy(() => import('../Form'))
const Select = lazy(() => import(/* webpackChunkName: "ReactSelect" */'react-select'))
const Description = lazy(() => import(/* webpackChunkName: "Description" */'../landing/Description'))

export default function PodTypeModulesShow({ pod_type_module, currency_exchange_rates, lang }) {
  const [totalPrice, setTotalPrice] = useState(pod_type_module.price)
  const [convertedPrice, setConvertedPrice] = useState(0)
  const [selectedCurrency, setSelectedCurrency] = useState({ value: 1, label: 'AED' })
  const settings = {
    autoplay: false,
    dots: true,
    rtl: lang === 'ar',
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const currencies = currency_exchange_rates.reduce((result, exchange_rate) => {
    result.push({ value: exchange_rate.rate, label: exchange_rate.code })
    return result
  },[{ value: 1, label: 'AED'}])

  const onCurrencyChange = currency => setSelectedCurrency(currency)

  useEffect(() => setConvertedPrice(totalPrice * +selectedCurrency.value), [totalPrice, selectedCurrency])

  const selectStyles = {
    option: provided => ({
      ...provided,
      backgroundColor: 'black',
      color: 'white'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'white'
    }),
    control: provided => ({
      ...provided,
      backgroundColor: 'black'
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: 'black'
    })
  }

  return (
    <>
      <div className="container container--main pb-12" style={{ maxWidth: 770 }}>
        <div className="slick--homepods-pod-type-modules mb-24">
          <Suspense fallback={<div></div>}>
            <Slider {...settings}>
              {
                pod_type_module.pod_type_module_images.map(image => (
                  <div key={image.id}>
                    { image.file_type.includes('image')
                      && <img className="mx-auto sm:h-500px w-full object-cover" alt={ lang === 'en' ? image.image_alt : image.arabic_image_alt} src={image.image.url} />
                      || <video className="h-500px w-full" controls>
                          <source src={image.image.url} />
                        </video>
                    }
                  </div>
                ))
              }
            </Slider>
          </Suspense>
        </div>

        <div className="mb-24">
          <div className="border-b-2 border-tuna-o-50 mb-10 pb-10 preview sm:preview-fs-24">
            <Suspense fallback={<div></div>}>
              <Description description={lang === 'en' ? pod_type_module.description : pod_type_module.arabic_description} />
            </Suspense>
          </div>

          <div className="flex justify-between mb-4 sm:text-2xl">
            <p>{I18n.t('homepods.pod_type_modules.show.size')}</p>
            <p className={lang === 'en' ? "pl-4" : "pr-4"}>{pod_type_module.size}</p>
          </div>

          <div className="flex justify-between mb-4 sm:text-2xl">
            <p>{I18n.t('homepods.pod_type_modules.show.floor_space')}</p>
            <p className={lang === 'en' ? "pl-4" : "pr-4"}>{pod_type_module.floor_space}</p>
          </div>

          <div className="flex justify-between mb-4 sm:text-2xl">
            <p>{I18n.t('homepods.pod_type_modules.show.height_below_ceiling')}</p>
            <p className={lang === 'en' ? "pl-4" : "pr-4"}>{pod_type_module.height_below_ceiling}</p>
          </div>

          <div className="flex justify-between mb-4 sm:text-2xl">
            <p>{I18n.t('homepods.pod_type_modules.show.toilet')}</p>
            <p className={lang === 'en' ? "pl-4" : "pr-4"}>{pod_type_module.toilet ? I18n.t('homepods.pod_type_modules.show.yes_') : I18n.t('homepods.pod_type_modules.show.no_')}</p>
          </div>
        </div>

        <div className="mb-12 relative">
          <div className="anchor" id="form" />
          <Suspense fallback={<div></div>}>
            <Form pod_type_module={pod_type_module} setTotalPrice={setTotalPrice} totalPrice={totalPrice} lang={lang} />
          </Suspense>
        </div>

        <div className="bg-tuna mb-6 p-8 rounded-lg text-white">
          <div className="sm:flex items-center justify-between">
            <p className="mb-6 sm:mb-0 sm:w-auto text-center w-full">{I18n.t('homepods.pod_type_modules.show.total_indicative_price')}:</p>

            <div className="flex sm:w-1/3 items-center justify-center mb-4 sm:justify-between sm:mb-0 w-full">
              <p className={`font-bold text-2xl sm:w-2/3 ${lang === 'en' ? 'text-right' : 'text-left'}`}>{formatCurrency(convertedPrice, 4)} </p>
              <Suspense fallback={<div></div>}>
                <Select className={`w-1/2 ${lang === 'en' ? 'pl-2' : 'pr-2'}`} value={selectedCurrency} options={currencies} onChange={onCurrencyChange} styles={selectStyles} />
              </Suspense>
            </div>
          </div>

          <p className={`text-center text-sm ${lang === 'en' ? 'sm:text-left' : 'sm:text-right'}`}>{I18n.t('homepods.pod_type_modules.show.please_read')}</p>
        </div>

        <p className="text-tuna">
          {I18n.t('homepods.pod_type_modules.show.this_price_includes')}
          <br />
          <br />
          {I18n.t('homepods.pod_type_modules.show.prior_to')}
          <br />
          <br />
          {I18n.t('homepods.pod_type_modules.show.to_ensure')}
        </p>
      </div>
    </>
  )
}
