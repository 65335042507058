import { Snackbar } from '@material-ui/core'
import React from 'react'
import Alert from '@material-ui/lab/Alert';

export default function Notification({ message, severity, open, setNotification }) {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ open: false, message, severity })
  }

  return (
    <div>
      <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      >
        <Alert onClose={handleClose} elevation={6} variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}