import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import axiosService from '../../common/AxiosService'
import Order from '../../models/Order'
import SimpleReactValidator from 'simple-react-validator'
const Select = lazy(() => import(/* webpackChunkName: "ReactSelect" */'react-select'))

export default function Form({ pod_type_module, setTotalPrice, totalPrice, lang }) {

  const [, forceUpdate] = useState()
  const simpleValidator = useRef(new SimpleReactValidator({ element: message => <div className="text-red-600">{message}</div>, autoForceUpdate: { forceUpdate } }))
  const [order, setOrder] = useState(new Order({ pod_type_module_id: pod_type_module.id }))

  const onChange = ({ target: { name, value }}) => setOrder({ ...order, [name]: value })
  useEffect(() => setTotalPrice(order.quantity * +pod_type_module.price), [order.quantity])

  const sendOrder = () => {
    if(simpleValidator.current.allValid()) {
      axiosService.post(`/${lang}/orders`, { order: { ...order, total_price: totalPrice } })
      .then(response => window.location = response.redirect_path)
    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1)
    }
  }

  const interiorFloorFinishOptions = [
    { label: I18n.t('homepods.pod_type_modules.show.tile'), value: 'Tile', color: 'green' },
    { label: I18n.t('homepods.pod_type_modules.show.vinyl'), value: 'Vinyl', color: 'blue' },
    { label: I18n.t('homepods.pod_type_modules.show.carpet'), value: 'Carpet', color: 'black' },
  ]

  const interiorWallPaintOptions = [
    { label: I18n.t('homepods.pod_type_modules.show.matt_ivory'), value: 'Matt Ivory', color: 'rgb(238,238,224)' },
    { label: I18n.t('homepods.pod_type_modules.show.matt_white'), value: 'Matt White', color: 'rgb(220,220,220)' },
  ]

  const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} className="react-select__option flex justify-between items-center">
        {data.label}
        <div className="circle w-3 h-3" style={{ backgroundColor: data.color }}></div>
      </div>
    )
  };

  const onSelectChange = attr => option => setOrder({ ...order, [attr]: option.value })

  const selectedInteriorFloorFinish = () => interiorFloorFinishOptions.find(option => option.value === order.interior_floor_finish)

  const selectedInteriorWallPaint = () => interiorWallPaintOptions.find(option => option.value === order.interior_wall_paint)

  return (
    <React.Fragment>
      <div className="flex flex-wrap justify-center mb-8 -mx-4">
        <div className="mb-8 px-4 sm:mb-0 sm:w-3/12 w-full">
          <div className="relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.quantity')} <span className="text-red-600">*</span></label>
            <input className="input input--homepods" name="quantity" onChange={onChange} type="number" value={order.quantity} min={1}  onBlur={()=>simpleValidator.current.showMessageFor('Quantity')} />
            {simpleValidator.current.message('Quantity', order.quantity, 'required|min:1,num')}
          </div>
        </div>

        <div className="px-4 sm:w-9/12 w-full">
          <div className="flex flex-wrap -mx-4">
            <div className="mb-8 px-4 sm:mb-0 sm:w-1/2 w-full">
              <div className="relative">
                <label className="label-over-field label-over-field--homepods" style={{ zIndex: 10 }}>{I18n.t('homepods.pod_type_modules.show.interior_wall_paint')} <span className="text-red-600">*</span></label>
                <Suspense fallback={<div></div>}>
                  <Select
                    className="w-full"
                    classNamePrefix="react-select"
                    name="interior_wall_paint"
                    components={{ Option: CustomOption }}
                    placeholder={I18n.t('homepods.pod_type_modules.show.select')}
                    value={selectedInteriorWallPaint()}
                    options={interiorWallPaintOptions}
                    onChange={onSelectChange("interior_wall_paint")}
                    onBlur={()=>simpleValidator.current.showMessageFor('Interior Wall Paint')}
                  />
                  {simpleValidator.current.message('Interior Wall Paint', order.interior_wall_paint, 'required')}
                </Suspense>
              </div>
            </div>

            <div className="px-4 sm:w-1/2 w-full">
              <div className="relative">
                <label className="label-over-field label-over-field--homepods" style={{ zIndex: 10 }}>{I18n.t('homepods.pod_type_modules.show.interior_floor_finish')} <span className="text-red-600">*</span></label>
                <Suspense fallback={<div></div>}>
                  <Select
                    className="w-full"
                    classNamePrefix="react-select"
                    name="interior_floor_finish"
                    components={{ Option: CustomOption }}
                    placeholder={I18n.t('homepods.pod_type_modules.show.select')}
                    value={selectedInteriorFloorFinish()}
                    options={interiorFloorFinishOptions}
                    onChange={onSelectChange("interior_floor_finish")}
                    onBlur={()=>simpleValidator.current.showMessageFor('Interior Floor Finish')}
                  />
                  {simpleValidator.current.message('Interior Floor Finish', order.interior_floor_finish, 'required')}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mb-12 sm:mb-6 -mx-4">
        <div className="px-4 sm:w-1/2 w-full">
          <div className="flex flex-wrap -mx-4">
            <div className="px-4 sm:w-1/2 w-full">
              <div className="mb-8 relative">
                <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.first_name')} <span className="text-red-600">*</span></label>
                <input className="input input--homepods" name="first_name" onChange={onChange} type="text" value={order.first_name} onBlur={()=>simpleValidator.current.showMessageFor('First name')} />
                {simpleValidator.current.message('First name', order.first_name, 'required')}
              </div>
            </div>

            <div className="px-4 sm:w-1/2 w-full">
              <div className="mb-8 relative">
                <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.last_name')} <span className="text-red-600">*</span></label>
                <input className="input input--homepods" name="last_name" onChange={onChange} type="text" value={order.last_name} onBlur={()=>simpleValidator.current.showMessageFor('Last name')} />
                {simpleValidator.current.message('Last name', order.last_name, 'required')}
              </div>
            </div>
          </div>

          <div className="mb-8 relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.address')} <span className="text-red-600">*</span></label>
            <input className="input input--homepods" name="address1" onChange={onChange} type="text" value={order.address1} onBlur={()=>simpleValidator.current.showMessageFor('Address 1')} />
            {simpleValidator.current.message('Address 1', order.address1, 'required')}
          </div>

          <div className="mb-8 relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.address2')}</label>
            <input className="input input--homepods" name="address2" onChange={onChange} type="text" value={order.address2} />
          </div>

          <div className="mb-8 relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.phone')} <span className="text-red-600">*</span></label>
            <input className="input input--homepods" name="phone" onChange={onChange} type="text" value={order.phone} onBlur={()=>simpleValidator.current.showMessageFor('Phone')} />
            {simpleValidator.current.message('Phone', order.phone, 'required')}
          </div>
        </div>

        <div className="px-4 sm:w-1/2 w-full">
          <div className="mb-8 relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.notes')}</label>
            <textarea
              className="textarea textarea--homepods"
              name="notes"
              onChange={onChange}
              value={order.notes}
            />
          </div>

          <div className="relative">
            <label className="label-over-field label-over-field--homepods">{I18n.t('homepods.pod_type_modules.show.email')} <span className="text-red-600">*</span></label>
            <input className="input input--homepods" name="email" onChange={onChange} type="text" value={order.email} onBlur={()=>simpleValidator.current.showMessageFor('E-mail')} />
            {simpleValidator.current.message('E-mail', order.email, 'required|email')}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between text-center">
        <a className="btn btn--primary-outlined btn--small mb-6 sm:mb-0 sm:w-auto w-full" href="https://wa.me/971566824263" target="_blank" style={{ lineHeight: '50px' }}>{I18n.t('homepods.pod_type_modules.show.speak_to')}</a>

        <button className="btn btn--primary btn--small sm:w-auto w-full" style={{ lineHeight: '50px' }} onClick={sendOrder} >{I18n.t('homepods.pod_type_modules.show.send_form')}</button>
      </div>
    </React.Fragment>
  )
}
