const axios = require("axios");

axios.default.defaults.xsrfCookieName = "CSRF-TOKEN"
axios.default.defaults.xsrfHeaderName = "X-CSRF-Token"
axios.default.defaults.withCredentials = true

const axiosService = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

axiosService.interceptors.response.use(
  ({ data }) => data,
  ({ response }) => Promise.reject(response)
)

export default axiosService