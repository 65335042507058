import React, { lazy, Suspense } from 'react'
import NotificationProvider from '../../../contexts/NotificationContext'
const ContactForm = lazy(() => import(/* webpackChunkName: "ContactForm" */'../../shared/contact/ContactForm'))

export default function ContactFormWrapper({ lang }) {

  return (
    <Suspense fallback={<div></div>}>
      <NotificationProvider>
        <ContactForm lang={lang} siteName="Unipods" />
      </NotificationProvider>
    </Suspense>
  )
}