export default class Order {

  constructor(order={}) {
    this.address1 = order.address1 || ''
    this.address2 = order.address2 || ''
    this.first_name = order.first_name || ''
    this.last_name = order.last_name || ''
    this.notes = order.notes || ''
    this.phone = order.phone || ''
    this.email = order.email || ''
    this.quantity = order.quantity || 1
    this.pod_type_module_id = order.pod_type_module_id
    this.interior_wall_paint = order.interior_wall_paint
    this.interior_floor_finish = order.interior_floor_finish
  }
}