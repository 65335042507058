import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    }
  },
  "ba": "Building Accessories",
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "hello": "Hello world",
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "homepods": {
    "dashboard": {
      "about": {
        "about_us": "About us",
        "an_additional_space": "An additional space without the burden of the additional space.",
        "and_that_is": "And that is exactly what also makes pods all the more exciting - you can turn it into anything you want, and fit it wherever you like. Which brings us to the reason why we stand here today.",
        "over_the_last": "Over the last years, we have designed, manufactured, and delivered specialized pods to over companies and businesses all over the country. These notable projects have successfully established Unipods LLC as the leader in pod innovation and technology today.",
        "space_comes_to_life": "A space comes to life through the heart and spirit of the residents that spend time in it. A home is a home because of the people that live inside of it - otherwise it is simply a building. And the same can be said for pods; without the personality and adornment of the homeowner, it is simply a mobile space.",
        "the_resident_experts": "The resident experts at Unipods LLC are committed to helping you create the space that you want and need. We are equipped with the best sourced materials and quality tools to bring your visions to life without the waste of time, money, and energy. With our experience, we guarantee a premium and seamless process from beginning to end you won’t have to get your hands dirty. We will design, build, deliver, and install a fully flexible and mobile space into your home.",
        "then_all_there": "Then all there’s left for you to do - is give it life.",
        "unipods_llc": "Unipods LLC is a leading off-site construction technology company that is a member of the AL RAJHI HOLDING, a Saudi-based Group with investments in more than 30 companies across the United Arab Emirates.",
        "we_believe": "We believe that the utilization of pods does not have to end in the commercial world. In fact, there are much more exciting opportunities for pods when we bring in a factor that only a home can offer - a life."
      },
      "index": {
        "additional_space1": "An additional space",
        "additional_space2": "without the burden of the additional space",
        "browse_through_our_catalog": "Browse through our catalog of premium pod designs for one that fits what you’re looking for. Select a pod to learn more about each pod’s unique features and design capabilities.",
        "here_is_where": "Here is where pods come in. We like to call it: an additional space without the burden of the additional space.",
        "nothing_beats": "Nothing beats the luxury of being able to stay at home and spend time with your loved ones. Nowadays, you can have meetings and type away on your laptop one minute, then share a meal with your family in the next - all in the comforts of your own home. Over time however, as the household and activities grow, so does the need for more space. And we all know that expanding a home doesn’t just mean being able to have another room; it also means having to spend money, time, and energy into building that extra space.",
        "office_pod": "Office Pod",
        "residential_pods_are": "Home pods are beautifully designed, functional external spaces curated specifically for homes and residential properties. With a variety of designs and modules, home pods provide modern-day solutions for those seeking to expand their space without the hassle and disruption of construction work in their home. Pods have the flexibility to be transformed into anything that fits your needs; whether that is a space to think and get work done, or a space to spend time with family and friends.",
        "select_a_pod_that_best_suits_you": "Select a pod that best suits you!",
        "select_and_continue": "Select and Continue",
        "select_your_pod": "Select your pod",
        "speak_to_an_expert": "Speak to an expert",
        "we_invite_you": "We invite you to think outside the box. Or rather, outside your existing space.",
        "what_is_a_residental_pod": "What is a home pod?"
      },
      "retail_pod": {
        "contact_form": "Contact form",
        "e_mail": "E-mail",
        "email": "Email:",
        "fax": "Fax:",
        "in_other_words": "In other words;",
        "ksa_office": "KSA Office",
        "ksa_office1": "Al Rajhi Holding Group",
        "ksa_office2": "King Fahad Road,",
        "ksa_office3": "Al Shaifa District",
        "ksa_office4": "Tamkeen Tower, 44th Floor",
        "ksa_office5": "Riyadh 11425, K. S. A.",
        "message": "Message",
        "name": "Name",
        "our_offices": "Our Offices",
        "phone": "Telephone:",
        "popup_stores": "Pop-up stores are in the lead of the retail race because they follow the crowd. The limited amount of time in different locations generates consistent buzz and curiosity - the kind that you’ll usually only get during an opening week. Flexibility and mobility is why pop-up stores stay ahead of the game.",
        "retail_pods_curated": "Retail pods are curated on-demand to fit any size, any look, and any use. From recreational spaces, to kiosks, to fashion stores, to food & drink stalls - these pods can be transformed into anything you can imagine.",
        "retail_pods_ultimate": "retail pods are the ultimate pop-up stores.",
        "retail_pods_versatile": "Retail pods are versatile, portable spaces with fully customizable features and designs to meet the needs of a modern-day business. Pods allow for businesses to be mobile without the costly effort and hassle of operating in different sites by renting or buying existing spaces.",
        "so_what_does": "So, what does this have to do with retail pods?",
        "subject": "Subject",
        "submit": "Send a message",
        "todays_business": "In today’s constantly evolving retail environment, businesses thrive when they are on the move. The current consumer landscape is flexible, mobile, and ever-changing. In order to keep up with the modern-day consumer, businesses must be equally adaptable and accessible. Gone are the days where long-term commitments in stationary locations are the norm. Constantly having to open branches to keep up with the target market is no longer sustainable. The keyword is now mobility - go where the crowd is.",
        "uae_head_office": "UAE Head Office",
        "uae_head_office1": "Al Ghail Industrial Area",
        "uae_head_office2": "P.O. Box 34132",
        "uae_head_office3": "Ras Al Khaimah, UAE"
      },
      "thanks": {
        "all_you_need": "All you need to do now is sit back and relax. Our resident experts will take a look at your request and will soon start the planning process to make it happen. Once the plan is clear, we will contact you and together we can bring your vision to life. So hang tight!",
        "back_to_home_page": "Back to Home Page",
        "congratulations": "Congratulations! You are one step closer to having your very own unique pod.",
        "select_another_pod": "Select another Pod",
        "so_what": "So what can you do from here?",
        "thank_you": "Thank you for filling out our form and providing us with your details. Your unique pod design request has just been sent to our team, and we are super excited to see what kind of pod we’ll get to design for you. "
      },
      "what_is_a_pod": {
        "beautiful_flexible": "Beautiful, Flexible, & Functional",
        "benefits_of_pods": "Benefits of pods",
        "everything_possible": "Everything is Possible from Home",
        "expand_the_space": "Expand the space in your home without the hassle of the process. Once you select and customize a pod through our website, we'll handle the rest. As the full package, we build, design, deliver, and install. All you need to do is sit back and relax*. Before you know it, you’ll have a brand new room and more space in your house. * Prior to installing the pod, please confirm with your local residential area, compound, or complex if any NOCs (No Objection Certificate) are required for the pod.",
        "explore_possibilities": "Explore the possibilities and excitement of life from the comforts of your very own home. Design and add spaces to increase the quality of the place you most value without sacrificing the space itself. Pods allow you to build rooms to cater to every need without ever needing to leave the property.",
        "more_space": "More space. Less hassle.",
        "no_construction": "No Construction at Home",
        "residential_pods": "Home pods are beautifully designed, functional external spaces curated specifically for homes and residential properties. With a variety of designs and modules, home pods provide modern-day solutions for those seeking to expand their space without the hassle and disruption of construction work in their home. Pods have the flexibility to be transformed into anything that fits your needs; whether that is a space to think and get work done, or a space to spend time with family and friends.",
        "what_is_a_pod": "What is a Pod?",
        "with_pods_you_dont": "With pods, you don’t have to worry about the noise, mess, and overall disruption of having construction going on in your living space. Pods are constructed at a secure, off-site location that is equipped with all the quality tools and materials we need to build your space. Once the pod is completed, we’ll deliver it right to your doorstep - just like that package you ordered online, only bigger and better.**** To ensure a smooth installation process, please ensure that the space you would like to place the pod has access to drainage, water, and electrical service connections.",
        "without_limitations": "Without the limitations of working on an existing stationary space, you have the freedom to design pods however you like and place it wherever you want. Using only quality and premium materials, pods are designed to withstand all kinds of environments with different weather conditions while being easy on the eyes. On top of that, pod dimensions can be adjusted and designed according to your specific space requirements."
      }
    },
    "pod_type_modules": {
      "show": {
        "address": "Address",
        "address2": "Address 2",
        "carpet": "Carpet",
        "email": "E-mail",
        "first_name": "First name",
        "floor_space": "Floor Space",
        "height_below_ceiling": "Height Below Ceiling",
        "interior_floor_finish": "Interior Floor Finish",
        "interior_wall_paint": "Interior Wall Paint",
        "last_name": "Last Name",
        "matt_ivory": "Matt Ivory",
        "matt_white": "Matt White",
        "no_": "No",
        "notes": "Notes",
        "order": "Order",
        "phone": "Phone",
        "please_read": "PLEASE READ IMPORTANT NOTES BELOW",
        "prior_to": "Prior to installing the pod, please confirm with your local residential area, compound, or complex if any NOCs (No Objection Certificate) are required for the pod.",
        "quantity": "Quantity",
        "select": "Select",
        "send_form": "Send form",
        "size": "Size",
        "speak_to": "Speak to an expert first",
        "this_price_includes": "This price includes the installation provision for an air conditioning unit. Please discuss with our experts your choice of unit and installation options at additional cost.",
        "tile": "Tile",
        "to_ensure": "To ensure a smooth installation process, please ensure that the space you would like to place the pod has access to drainage, water, and electrical service connections.",
        "toilet": "Toilet",
        "total_indicative_price": "Total Indicative price",
        "vinyl": "Vinyl",
        "yes_": "Yes"
      }
    },
    "pod_types": {
      "index": {
        "view": "View"
      },
      "show": {
        "continue": "Continue",
        "floor_space": "Floor Space",
        "height_below_ceiling": "Height Below Ceiling",
        "no_": "No",
        "size": "Size",
        "starting_price": "Starting price",
        "toilet": "Toilet",
        "yes_": "Yes"
      }
    }
  },
  "hrss": "Hot Rolled Steel Structures (HRSS)",
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fbf640e6c50 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fbf640c6bf8 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/activesupport-6.1.3.1/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fbf640c6c20 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/activesupport-6.1.3.1/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pebss": "Pre-Engineered Steel Building Steel Structures (PEBSS)",
  "shared": {
    "homepods": {
      "footer": {
        "about_company": "About company",
        "contact_us": "Contact Us homepods",
        "customer_service": "Customer Service",
        "get_in_touch": "Get in touch",
        "information": "Information",
        "work_with_us": "Work With US"
      },
      "header": {
        "about_us": "About Us",
        "residential_pod": "Home pod",
        "retail_pod": "Retail Pod",
        "what_is_a_pod": "What is a Pod?"
      }
    }
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  }
});
