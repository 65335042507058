import React, { lazy, Suspense, useState } from 'react'
import { formatCurrency } from '../../../common/helper-methods'
const Description = lazy(() => import(/* webpackChunkName: "Description" */'../landing/Description'))

export default function PodTypesShow({ podTypeModules, lang }) {
  const [selectedPodTypeModule, setSelectedPodTypeModule] = useState(podTypeModules[0])

  return (
    <>
      <div className="flex flex-wrap -mx-4 text-center">
        { podTypeModules.map(podTypeModule => (
            <div key={podTypeModule.id} className="mb-16 px-4 sm:w-4/12">
              <div className={`pod-img-container ${podTypeModule.id === selectedPodTypeModule.id ? 'active' : ''}`}>
                <img className="pod-img" src={podTypeModule.hero_image.url} alt={lang === 'en' ? podTypeModule.hero_image_alt : podTypeModule.arabic_hero_image_alt} onClick={() => setSelectedPodTypeModule(podTypeModule)}/>
              </div>

              <p className="font-bold sm:text-2xl text-xl">{lang === 'en' ? podTypeModule.name : podTypeModule.arabic_name}</p>
            </div>
          ))
        }
      </div>
      <div className="bg-tuna sm:flex items-center justify-between mb-12 p-8 rounded-lg text-white">
        { selectedPodTypeModule.id
          && <a className="btn btn--primary btn--primary-white mb-6 sm:mb-0 sm:w-auto text-center w-full" href={`/${lang}/pod_type_modules/${selectedPodTypeModule.id}-${selectedPodTypeModule.slug}`}>{I18n.t('homepods.pod_types.show.continue')}</a>
          || <p>Please select a module before continue</p>
        }
        <p className="flex items-center">{I18n.t('homepods.pod_types.show.starting_price')} <span className={`font-bold ${lang === 'en' ? 'ml-2' : 'mr-2'} text-2xl`}>{formatCurrency(selectedPodTypeModule.price)} AED</span></p>
      </div>

      <div className="border-b-2 border-tuna-o-50 mb-10 pb-10 preview sm:preview-fs-24">
          <Suspense fallback={<div></div>}>
            <Description description={lang === 'en' ? selectedPodTypeModule.description : selectedPodTypeModule.arabic_description} />
          </Suspense>
      </div>

      <div className="flex justify-between mb-4 sm:text-2xl">
        <p>{I18n.t('homepods.pod_types.show.size')}</p>
        <p className={lang === 'en' ? "pl-4" : "pr-4"}>{selectedPodTypeModule.size}</p>
      </div>

      <div className="flex justify-between mb-4 sm:text-2xl">
        <p>{I18n.t('homepods.pod_types.show.floor_space')}</p>
        <p className={lang === 'en' ? "pl-4" : "pr-4"}>{selectedPodTypeModule.floor_space}</p>
      </div>

      <div className="flex justify-between mb-4 sm:text-2xl">
        <p>{I18n.t('homepods.pod_types.show.height_below_ceiling')}</p>
        <p className={lang === 'en' ? "pl-4" : "pr-4"}>{selectedPodTypeModule.height_below_ceiling}</p>
      </div>

      <div className="flex justify-between mb-4 sm:text-2xl">
        <p>{I18n.t('homepods.pod_types.show.toilet')}</p>
        <p className={lang === 'en' ? "pl-4" : "pr-4"}>{selectedPodTypeModule.toilet ? I18n.t('homepods.pod_types.show.yes_') : I18n.t('homepods.pod_types.show.no_')}</p>
      </div>
    </>
  )
}