import React from 'react'
import Description from '../landing/Description'

export default function PodTypesIndex({ pods, lang }) {
  return (
    <div className="flex flex-wrap -mx-4 text-center">
      {
        pods.map(pod => {
          return (
            <div key={pod.id} className="mb-16 px-4 sm:w-4/12">
              <div className="pod-img-container">
                <a href={`/${lang}/pod_types/${pod.id}-${pod.slug}`}>
                  <img className="pod-img" src={pod.hero_image.url} alt={lang === 'en' ? pod.hero_image_alt : pod.arabic_hero_image_alt} />
                </a>

                <a className="btn btn--pod" style={{ zIndex: 10 }} href={`/${lang}/pod_types/${pod.id}-${pod.slug}`}>{I18n.t('homepods.pod_types.index.view')}</a>
              </div>

              <h2 className="font-bold sm:text-2xl text-xl">{lang === 'en' ? pod.name : pod.arabic_name}</h2>

              <div className="pod-type-box-text">
                <Description description={lang === 'en' ?  pod.description : pod.arabic_description} />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}