import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["ar"] = I18n.extend((I18n.translations["ar"] || {}), {
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "فشل التحقّق من: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "لا يمكن حذف السجل لوجود سجلات تعتمد عليه %{record}",
          "has_one": "لا يمكن حذف السجل لوجود سجل يعتمد عليه %{record}"
        }
      }
    }
  },
  "ba": "Building Accessories",
  "date": {
    "abbr_day_names": [
      "الأحد",
      "الإثنين",
      "الثّلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السّبت"
    ],
    "abbr_month_names": [
      null,
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر"
    ],
    "day_names": [
      "الأحد",
      "الإثنين",
      "الثّلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السّبت"
    ],
    "formats": {
      "default": "%d-%m-%Y",
      "long": "%e %B، %Y",
      "short": "%e %b"
    },
    "month_names": [
      null,
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "few": "حوالي %{count} ساعات",
        "many": "حوالي %{count} ساعة",
        "one": "حوالي ساعة واحدة",
        "other": "حوالي %{count} ساعة",
        "two": "حوالي ساعتان",
        "zero": "حوالي صفر ساعات"
      },
      "about_x_months": {
        "few": "حوالي %{count} أشهر",
        "many": "حوالي %{count} شهر",
        "one": "حوالي شهر واحد",
        "other": "حوالي %{count} شهر",
        "two": "حوالي شهران",
        "zero": "حوالي صفر أشهر"
      },
      "about_x_years": {
        "few": "حوالي %{count} سنوات",
        "many": "حوالي %{count} سنة",
        "one": "حوالي سنة",
        "other": "حوالي %{count} سنة",
        "two": "حوالي سنتان",
        "zero": "حوالي صفر سنوات"
      },
      "almost_x_years": {
        "few": "ما يقرب من %{count} سنوات",
        "many": "ما يقرب من %{count} سنة",
        "one": "تقريبا سنة واحدة",
        "other": "ما يقرب من %{count} سنة",
        "two": "ما يقرب من سنتين",
        "zero": "ما يقرب من صفر سنوات"
      },
      "half_a_minute": "نصف دقيقة",
      "less_than_x_minutes": {
        "few": "أقل من %{count} دقائق",
        "many": "أقل من %{count} دقيقة",
        "one": "أقل من دقيقة",
        "other": "أقل من %{count} دقيقة",
        "two": "أقل من دقيقتان",
        "zero": "أقل من صفر دقائق"
      },
      "less_than_x_seconds": {
        "few": "أقل من %{count} ثوان",
        "many": "أقل من %{count} ثانية",
        "one": "أقل من ثانية",
        "other": "أقل من %{count} ثانية",
        "two": "أقل من ثانيتان",
        "zero": "أقل من صفر ثواني"
      },
      "over_x_years": {
        "few": "أكثر من %{count} سنوات",
        "many": "أكثر من %{count} سنة",
        "one": "أكثر من سنة",
        "other": "أكثر من %{count} سنة",
        "two": "أكثر من سنتين",
        "zero": "أكثر من صفر سنوات"
      },
      "x_days": {
        "few": "%{count} أيام",
        "many": "%{count} يوم",
        "one": "يوم واحد",
        "other": "%{count} يوم",
        "two": "يومان",
        "zero": "صفر أيام"
      },
      "x_minutes": {
        "few": "%{count} دقائق",
        "many": "%{count} دقيقة",
        "one": "دقيقة واحدة",
        "other": "%{count} دقيقة",
        "two": "دقيقتان",
        "zero": "صفر دقائق"
      },
      "x_months": {
        "few": "%{count} أشهر",
        "many": "%{count} شهر",
        "one": "شهر واحد",
        "other": "%{count} شهر",
        "two": "شهران",
        "zero": "صفر أشهر"
      },
      "x_seconds": {
        "few": "%{count} ثوان",
        "many": "%{count} ثانية",
        "one": "ثانية واحدة",
        "other": "%{count} ثانية",
        "two": "ثانيتان",
        "zero": "صفر ثواني"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "اليوم",
      "hour": "ساعة",
      "minute": "دقيقة",
      "month": "الشهر",
      "second": "ثانية",
      "year": "السنة"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{message}",
    "messages": {
      "accepted": "يجب أن تقبل %{attribute}",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "لا يمكن أن يكون محتوى %{attribute} فارغاً",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "محتوى %{attribute} لا يتوافق مع %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
      "empty": "لا يمكن أن يكون محتوى %{attribute} فارغاً",
      "equal_to": "يجب أن يساوي طول %{attribute} %{count}",
      "even": "يجب أن يكون عدد %{attribute} زوجياً",
      "exclusion": "حقل %{attribute} محجوز",
      "expired": "has expired, please request a new one",
      "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "greater_than": "ميجب أن يكون عدد %{attribute} أكبر من %{count}",
      "greater_than_or_equal_to": "يجب أن يكون عدد %{attribute} أكبر أو يساوي %{count}",
      "inclusion": "%{attribute} غير وارد في القائمة",
      "invalid": "محتوى %{attribute} غير صالح",
      "less_than": "يجب أن يكون عدد %{attribute} أصغر من %{count}",
      "less_than_or_equal_to": "يجب أن يكون عدد %{attribute} أصغر أو  يساوي %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "يجب أن يكون محتوى %{attribute} رقماً",
      "not_an_integer": "يجب أن يكون عدد %{attribute} عدد صحيحاً",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "يجب أن يكون عدد %{attribute} عدداً فردياً",
      "other_than": {
        "few": "طول %{attribute} يجب ألاّ يكون %{count} أحرف",
        "many": "طول %{attribute} يجب ألاّ يكون %{count} حرفاً",
        "one": "طول %{attribute} يجب ألاّ يكون حرفاً واحداً",
        "other": "طول %{attribute} يجب ألاّ يكون %{count} حرفاً",
        "two": "طول %{attribute} يجب ألاّ يكون حرفان",
        "zero": "طول %{attribute} يجب ألاّ يكون صفر حرف"
      },
      "present": "يجب ترك حقل %{attribute} فارغاً",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "taken": "حقل %{attribute} محجوز مسبقاً",
      "too_long": {
        "few": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو %{count} حروف)",
        "many": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو %{count} حرف)",
        "one": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو حرف واحد)",
        "other": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو %{count} حرف)",
        "two": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو حرفان)",
        "zero": "محتوى %{attribute} أطول من اللّازم (الحد الأقصى هو ولا حرف)"
      },
      "too_short": {
        "few": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو %{count} حروف)",
        "many": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو %{count} حرف)",
        "one": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو حرف واحد)",
        "other": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو %{count} حرف)",
        "two": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو حرفان)",
        "zero": "محتوى %{attribute} أقصر من اللّازم (الحد الأدنى هو ولا حرف)"
      },
      "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
      "wrong_length": {
        "few": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون %{count} أحرف)",
        "many": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون %{count} حرف)",
        "one": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون حرف واحد)",
        "other": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون %{count} حرف)",
        "two": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون حرفان)",
        "zero": "طول %{attribute} غير مطابق للحد المطلوب (يجب أن يكون ولا حرف)"
      }
    },
    "template": {
      "body": "يُرجى التحقّق من الحقول التّالية:%{attribute}",
      "header": {
        "few": "ليس بالامكان حفظ %{model} لسبب وجود %{count} أخطاء.",
        "many": "ليس بالامكان حفظ %{model} لسبب وجود %{count} خطأ.",
        "one": "ليس بالامكان حفظ %{model} لسبب وجود خطأ واحد.",
        "other": "ليس بالامكان حفظ %{model} لسبب وجود %{count} خطأ.",
        "two": "ليس بالامكان حفظ %{model} لسبب وجود خطئان.",
        "zero": "ليس بالامكان حفظ %{model} لسبب ولا خطأ."
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "hello": "Hello world",
  "helpers": {
    "select": {
      "prompt": "يُرجى الاختيار"
    },
    "submit": {
      "create": "%{model} إنشاء",
      "submit": "%{model} حِفظ",
      "update": "%{model} تحديث"
    }
  },
  "homepods": {
    "dashboard": {
      "about": {
        "about_us": "لمحة عنّا",
        "an_additional_space": " مساحة إضافيّة بدون عبء المساحة الإضافيّة.",
        "and_that_is": "وهذا هو بالضبط ما يجعل من البود اختراعاً مشوّقاً – إذ يمكنكم تحويله إلى أي شيء تريدونه ووضعه أينما شئتم. ولهذا السبب نحن اليوم هنا.",
        "over_the_last": "على مدى السنوات الـ __الماضية، قمنا بتصميم مجموعات متخصصّة من البود وتصنيعها وتسليمها لأكثر من __ شركة ومؤسّسة في جميع أنحاء البلاد. وقد نجحت هذه المشاريع البارزة في إعطاء شركة Unipods LLC مكانتها كشركة رائدة في مجال الابتكار وتكنولوجيا البود اليوم.",
        "space_comes_to_life": "تنبض الأماكن بالحياة بفضل قلوب سكّانها وأنشطتهم وحيويّتهم، فيصبح المنزل بيتًا بفضل سكّانه، وإلّا فيبقى مجرد مبنى. وينطبق الشيء نفسه على البود: بدون شخصية صاحب المنزل وزينته، يبقى البود مجرّد مساحة متنقّلة.",
        "the_resident_experts": "الخبراء الدائمون في Unipods LLC ملتزمون بمساعدتكم على إنشاء المساحة التي تريدونها وتحتاجونها. ولذلك نستخدم أفضل المواد والأدوات العالية الجودة لتحقيق رؤيتكم من دون إضاعة الوقت والمال والطاقة. بفضل خبرتنا الواسعة، نضمن لكم عمليّة متميّزة وسلسة من البداية إلى النهاية من دون أي مجهود من قبلكم. فسوف نهتمّ بالتصميم والبناء والتوصيل والتركيب لنقدّم لكم مساحة مرنة ومتحرّكة بالكامل في منزلكم.",
        "then_all_there": "لن يبقى عليكم سوى جعلها تنبض بالحياة!",
        "unipods_llc": "Unipods LLC شركة رائدة في مجال تقنيات البناء خارج الموقع وهي عضو في مجموعة الراجحي القابضة مقرّها المملكة العربيّة السعودية ولها استثمارات في أكثر من 30 شركة في جميع أنحاء الإمارات العربيّة المتّحدة.",
        "we_believe": "نؤمن أنّ استخدام البود لا يقتصر على العالم التجاري فحسب، بل له استخدامات أكثر تشويقًا عندما يتدخل عامل لا نجده سوى في البيوت، ألا وهو الحياة."
      },
      "index": {
        "additional_space1": "مساحة إضافيّة",
        "additional_space2": "بدون عبء المساحة الإضافيّة.",
        "browse_through_our_catalog": "تصفّحوا الكاتالوغ الخاص بنا واختاروا التصميم الذي يناسب احتياجاتكم من بين مجموعة البود الواسعة والفاخرة. اضغطوا على البود الذي تريدون لمعرفة المزيد عن ميزاته الفريدة وإمكانيّات تصميمه.",
        "here_is_where": "هنا يأتي دور البود، أو كما نحب أن نسمّيه: مساحة إضافيّة بدون عبء المساحة الإضافيّة.",
        "nothing_beats": "لا شيء يفوق رفاهيّة البقاء في المنزل وقضاء الوقت مع أحبائنا. واليوم، أصبح بإمكانكم عقد الاجتماعات والعمل على الكمبيوتر ومن ثمّ مشاركة وجبة مع عائلتكم في أقلّ من دقيقة واحدة – كلّ ذلك في قلب منزلكم. غير أنّه ومع مرور الوقت ونموّ الأسرة والأنشطة، تزداد الحاجة إلى مساحة أكبر. وندرك جميعًا أن توسيع المنزل لا يعني إضافة غرفة جديدة فحسب، بل يعني أيضًا إنفاق المال والوقت والطاقة في بناء تلك المساحة الإضافيّة.",
        "office_pod": "كبسولة للمكتب",
        "residential_pods_are": "يتّسم البود السكني بتصميمه الجميل، وهو عبارة عن مساحات خارجية عمليّة مصمّمة خصيصًا للمنازل والعقارات السكنية ويتوفّر بمجموعة واسعة من التصاميم والنماذج. ويقدّم البود السكنيّ حلولًا حديثة لمن يرغب في توسيع مساحته من دون اللجوء إلى أعمال البناء المزعجة والمتعبة في منزله. وللبود استخدامات متعدّدة حيث يمكن تحويله إلى أي شيء يناسب احتياجاتكم، سواء كانت مساحة للتفكير وإنجاز العمل أو مساحة لقضاء الوقت مع العائلة والأصدقاء.",
        "select_a_pod_that_best_suits_you": "اختاروا البود الأفضل لكم.",
        "select_and_continue": "إختر وتابع",
        "select_your_pod": "اختيار البود",
        "speak_to_an_expert": "التواصل مع خبير",
        "we_invite_you": "ندعوكم اليوم إلى التفكير خارج حدود المألوف، أو بالأحرى خارج مساحتكم الحاليّة.",
        "what_is_a_residental_pod": "ما هو البود السكنيّ؟"
      },
      "retail_pod": {
        "contact_form": "اتصل بنا",
        "e_mail": "البريد الإلكتروني",
        "email": "بريد إلكتروني:",
        "fax": "الفاكس:",
        "in_other_words": "بعبارة أخرى،",
        "ksa_office": "مكتب المملكة العربية السعودية",
        "ksa_office1": "مجموعة الراجحي القابضة",
        "ksa_office2": "طريق الملك فهد ،",
        "ksa_office3": "حي الشفاء",
        "ksa_office4": "برج تمكين ، الدور 44",
        "ksa_office5": "الرياض 11425 ، ك.",
        "message": "رسالة",
        "name": "اسم",
        "our_offices": "مكاتبنا",
        "phone": "هاتف:",
        "popup_stores": "أصبحت محلّات البيع المؤقتة أفضل الحلول لأنّها تتبع الحشد أينما كان. كذلك، فالظهور لوقت محدود في مواقع مختلفة يثير نوعًا من الاهتمام والفضول لن تحصلوا عليه إلّا خلال الأسبوع الأوّل من افتتاح أي محلّ تجاري. فالمرونة والحركية هما العنصران الأساسيان اللذان يجعلان محلّات البيع المؤقّتة أفضل استراتيجية تجارية في عصرنا هذا. ",
        "retail_pods_curated": "يتم تصنيع بود البيع بالتجزئة عند الطلب ليناسب أي حجم وأي مظهر وأي استخدام. ويمكن تحويل هذا البود إلى أي شيء تتخيّلونه، من المساحات الترفيهية إلى الأكشاك ومتاجر الأزياء والمطاعم.",
        "retail_pods_ultimate": "بود البيع بالتجزئة هو أفضل خيار لمحلّات البيع المؤقّتة.",
        "retail_pods_versatile": "بود البيع بالتجزئة عبارة عن مساحات متعدّدة الاستخدامات ومحمولة ذات ميزات وتصاميم قابلة للتخصيص بالكامل لتلبية احتياجات الشركات والمؤسّسات الحديثة. ويسمح البود للشركات بالتنقّل من دون جهد أو كلفة أو عبء العمل في مواقع مختلفة عن طريق استئجار مساحات موجودة أو شرائها.",
        "so_what_does": "إذاً، ما علاقة ذلك ببود البيع بالتجزئة؟",
        "subject": "موضوع",
        "submit": "ارسل رسالة",
        "todays_business": "بيئة البيع بالتجزئة في تطوّر دائم، وفي بيئتنا اليوم، تزدهر الشركات التي لا تغرس جذورها في مكان واحد، لا بل تفرد جناحَيْها وتتنقّل باستمرار. لأن فئات المستهلكين المستهدفة متقلّبة ومتحرّكة ومتغيّرة باستمرار. ولمواكبة المستهلك في العصر الحديث، على الشركات التكيّف والبقاء على مسافة قريبة من المستهلك. فلم تعد الالتزامات الطويلة الأجل في مواقع ثابتة هي القاعدة، ولم يعد افتتاح الفروع لمواكبة السوق المستهدف أمرًا مستداماً، الكلمة الرئيسية اليوم هي الحركيّة، أي الذهاب حيث تتجمّع الحشود.",
        "uae_head_office": "المكتب الرئيسي لدولة الإمارات العربية المتحدة",
        "uae_head_office1": "منطقة الغيل الصناعية",
        "uae_head_office2": "ص. ب 34132",
        "uae_head_office3": "رأس الخيمة ، الإمارات العربية المتحدة"
      },
      "thanks": {
        "all_you_need": "كلّ ما عليكم القيام به الآن هو الجلوس والاسترخاء. وسيقوم خبراؤنا المقيمون بمراجعة طلبكم وسيبدأون قريبًا بعمليّة التخطيط والتنفيذ. بمجرّد وضوح الخطّة، سنتّصل بكم للعمل معًا على جعل رؤيتكم تنبض بالحياة. قريباً، سيتحوّل الحلم حقيقة!",
        "back_to_home_page": "الرجوع إلى الصفحة الرئيسية",
        "congratulations": "مبروك! أنتم على وشك الحصول على كبسولتكم الفريدة الخاصّة بكم.",
        "select_another_pod": "اختيار كبسولة أخرى",
        "so_what": "ماذا عن الخطوة القادمة؟",
        "thank_you": "نشكركم لملء الاستمارة وتزويدنا بالتفاصيل الخاصّة بكم. لقد تمّ إرسال طلب تصميم الكبسولة الفريدة الخاصّ بكم إلى فريق عملنا. ونتطلّع بفارغ الصبر إلى معرفة نوع الكبسولة التي ترغبون في أن نصمّمها لكم."
      },
      "what_is_a_pod": {
        "beautiful_flexible": "جمال ومرونة وعمليّة",
        "benefits_of_pods": "مزايا البود",
        "everything_possible": "كلّ شيء ممكن من قلب المنزل",
        "expand_the_space": "أصبح بإمكانكم توسيع مساحة منزلكم من دون عناء. فليس عليكم سوى اختيار البود وتخصيصه من خلال موقعنا ونحن سنهتمّ بالباقي. كما نقدّم لكم عرضاً كاملاً متكاملاً حيث نهتمّ بالتصميم والتوصيل والتركيب، ليس عليكم سوى الجلوس والاسترخاء* وستحصلون على غرفة جديدة تمامًا ومساحة أكبر في منزلكم بلمح البصر. * قبل تثبيت البود، يُرجى التأكّد من عدم الحاجة إلى أي شهادات عدم اعتراض من منطقتكم السكنية أو مجمّعكم السكني.",
        "explore_possibilities": "استكشفوا الإمكانيات والمغامرات التي تقدّمها لكم الحياة بينما تسترخون في قلب منزلكم. فقد بات بإمكانكم تصميم مساحات إضافيّة لتحسين المكان الأعزّ إلى قلبكم من دون خسارة أي مساحة. كما يسمح لكم البود ببناء غرف لتلبية جميع الاحتياجات من دون مغادرة العقار.",
        "more_space": "مساحة أكبر. متاعب أقلّ",
        "no_construction": "لا أعمال بناء في المنزل",
        "residential_pods": "تّسم البود السكنيّ بتصميمه الجميل، وهو عبارة عن مساحات خارجية عمليّة مصمّمة خصيصًا للمنازل والعقارات السكنية، يتوفّر بمجموعة واسعة من التصاميم والنماذج. ويقدّم البود السكنيّ حلولًا حديثة لمن يرغب في توسيع مساحته من دون اللجوء إلى أعمال البناء المزعجة والمتعبة في منزله. وللبود استخدامات متعدّدة حيث يمكن تحويله إلى أي شيء يناسب احتياجاتكم، سواء كانت مساحة للتفكير وإنجاز الأعمال أو مساحة لقضاء الوقت مع العائلة والأصدقاء.",
        "what_is_a_pod": "ما هو البود",
        "with_pods_you_dont": "بفضل البود، لا حاجة للقلق بشأن الضوضاء والفوضى والإزعاج نتيجة أعمال البناء في مساحة المعيشة الخاصّة بكم. يتم تصنيع البود في مكان آمن خارج الموقع ومجهّز بجميع الأدوات والمواد العالية الجودة اللازمة لبنائه. بعد اكتمال البود، نقوم بتوصيله مباشرة إلى قلب داركم – تمامًا مثل أي غرض تطلبونه عبر الإنترنت، ولكن بحجم أكبر وجودة أفضل. **** لضمان عملية تركيب سلسة، يُرجى التأكد من أنّ المساحة التي سيتمّ تركيب البود فيها موصولة بنظام الصرف والمياه والخدمات الكهربائيّة.",
        "without_limitations": "من دون قيود العمل على مساحة ثابتة، تملكون كلّ الحرية في تصميم البود كيفما شئتم ووضعه في أي مكان تريدونه. فقد صُمّم البود باستخدام مواد عالية الجودة لتحمّل جميع أنواع البيئات ذات الظروف الجوية المختلفة وإرضاء أصحاب الذوق الرفيع بتفاصيله الجذابة. كذلك، يمكن تعديل قياسات البود وتصميمه وفقًا لمتطلّبات المساحة الخاصّة بكم."
      }
    },
    "pod_type_modules": {
      "show": {
        "address": "عنوان",
        "address2": "العنوان 2",
        "carpet": "السجاد",
        "email": "البريد الإلكتروني",
        "first_name": "الاسم الأول",
        "floor_space": "المساحة الأرضية",
        "height_below_ceiling": "الارتفاع تحت السقف",
        "interior_floor_finish": "تشطيبات أرضية داخلية",
        "interior_wall_paint": "طلاء الجدران الداخلية",
        "last_name": "اسم العائلة",
        "matt_ivory": "مات ايفوري",
        "matt_white": "مات وايت",
        "no_": "كلا",
        "notes": "ملاحظات",
        "order": "طلب",
        "phone": "هاتف",
        "please_read": "يرجى قراءة الملاحظات المهمة أدناه",
        "prior_to": "قبل تثبيت البود، يُرجى التأكّد من عدم الحاجة إلى أي شهادات عدم اعتراض من منطقتكم السكنية أو مجمّعكم السكني.",
        "quantity": "كمية",
        "select": "يختار",
        "send_form": "إرسال النموذج",
        "size": "الحجم",
        "speak_to": "تحدث إلى خبير أولاً",
        "this_price_includes": "يشمل هذا السعر التركيب لوحدة تكييف الهواء. يرجى مناقشة اختيارك للوحدة وخيارات التركيب مع خبرائنا بتكلفة إضافية.",
        "tile": "البلاط",
        "to_ensure": "لضمان عملية تركيب سلسة، يُرجى التأكد من أنّ المساحة التي سيتمّ تركيب البود فيها موصولة بنظام الصرف والمياه والخدمات الكهربائيّة.",
        "toilet": "دورة مياه",
        "total_indicative_price": "السعر الإرشادي الإجمالي",
        "vinyl": "فينيل",
        "yes_": "نعم"
      }
    },
    "pod_types": {
      "index": {
        "view": "إختر"
      },
      "show": {
        "continue": "تابع",
        "floor_space": "المساحة الأرضية",
        "height_below_ceiling": "الارتفاع تحت السقف",
        "no_": "كلا",
        "size": "الحجم",
        "starting_price": "السعر المبدئي",
        "toilet": "دورة مياه",
        "yes_": "نعم"
      }
    }
  },
  "hrss": "Hot Rolled Steel Structures (HRSS)",
  "i18n": {
    "plural": {
      "keys": [
        "zero",
        "one",
        "two",
        "few",
        "many",
        "other"
      ],
      "rule": "#<Proc:0x00007fbf640e5be8 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/rails-i18n-6.0.0/lib/rails_i18n/../../rails/pluralization/ar.rb:5 (lambda)>"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u %n",
        "precision": 3,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "KWD"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "مليار",
          "million": "مليون",
          "quadrillion": "كدريليون",
          "thousand": "ألفّ",
          "trillion": "تريليون",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "Bytes",
            "many": "Bytes",
            "one": "Byte",
            "other": "Bytes",
            "two": "Bytes",
            "zero": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fbf640c6bf8 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/activesupport-6.1.3.1/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fbf640c6c20 /Users/balazskrajcs/.rvm/gems/ruby-2.7.0@al_rajihi/gems/activesupport-6.1.3.1/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "pebss": "Pre-Engineered Steel Building Steel Structures (PEBSS)",
  "shared": {
    "homepods": {
      "footer": {
        "about_company": "عن الشركة",
        "contact_us": "اتصل بنا",
        "customer_service": "خدمة العملاء",
        "get_in_touch": "ابقى على تواصل",
        "information": "معلومة",
        "work_with_us": "اعمل معنا"
      },
      "header": {
        "about_us": "لمحة عنّا",
        "residential_pod": "البود السكنيّ",
        "retail_pod": "بود البيع بالتجزئة",
        "what_is_a_pod": "ما هو البود"
      }
    }
  },
  "support": {
    "array": {
      "last_word_connector": " و ",
      "two_words_connector": " و ",
      "words_connector": " ، "
    }
  },
  "time": {
    "am": "صباحًا",
    "formats": {
      "default": "%a %b %d %H:%M:%S %Z %Y",
      "long": "%d %B, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "مساءً"
  }
});
