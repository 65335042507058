require('@rails/ujs').start()
//require('turbolinks').start()
require('@rails/activestorage').start()
require('jquery')
require('./vendors/slick.min')
require('./slick')
require('./vendors/lightbox')
require('./vendors/select2.min')
require('./select2')
window.I18n = require('i18n-js')
require('../bundles/i18n/en.js')
require('../bundles/i18n/ar.js')

var componentRequireContext = require.context('components/homepods', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);