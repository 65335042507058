$(document).ready(() => {
  $('.js-slick-landing').slick({
    centerMode: true,
    centerPadding: '10px',
    dots: true,
    infinite: false,
    slidesToScroll: 3,
    slidesToShow: 1,
    variableWidth: true,
  })

  $('.js-slick-single').slick({
    arrows: true,
    dots: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  })

  $('.js-slick-news').slick({
    dots: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  })

  $('.js-slick-news-no-arrows').slick({
    arrows: false,
    dots: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  })

  if ($(window).width() < 1024) {
    $('.js-slick-tablet').slick({
      dots: true,
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
    })
  }

  $('.js-slick-cladtech-landing').slick({
    centerMode: true,
    centerPadding: '10px',
    dots: false,
    infinite: true,
    slidesToScroll: 3,
    slidesToShow: 1,
    variableWidth: true,
  })


  $('.js-slick-3').slick({
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      },
    ],
  })
})
