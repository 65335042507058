import React, { useState } from 'react'
import Notification from '../components/shared/Notification'
export const NotificationContext = React.createContext({})

export default function NotificationProvider(props) {
  const [notification, setNotification] = useState({
    open: false,
    severity: 'success',
    message: ''
  })

  const notify = ({ message, open = true, severity = 'success' }) => setNotification({ open, severity, message })

  return (
    <NotificationContext.Provider value={{ notify }}>
      {props.children}
      <Notification message={notification.message} severity={notification.severity} open={notification.open} setNotification={setNotification} />
    </NotificationContext.Provider>
  )
}