export function formatCurrency(value, limit) {
  if(value) {
    const stringValue = '' + value
    const [intPart, fractionPart] = stringValue.split('.')
    let result = ''
    for(let i = intPart.length - 1; i >= 0; i--) {
      if(!((intPart.length -1 - i) % 3) && i !== intPart.length - 1) result = ',' + result
      result = intPart[i] + result
    }
    if(fractionPart) {
      result += '.'
      for(let i = 0; i < (!limit || limit > fractionPart.length ? fractionPart.length : limit); i++) {
        if(!(i % 3) && i !== 0) result += ','
        result += fractionPart[i]
      }
    }
    return result
  }
}