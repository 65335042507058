import React, { lazy, Suspense, useEffect, useState } from 'react'
const Description = lazy(() => import(/* webpackChunkName: "Description" */'./Description'))
const Slider = lazy(() => import(/* webpackChunkName: "Slider" */'react-slick'))

export default function PodSelect({ pods, lang }) {
  const [active, setActive] = useState(pods[0] && pods[0].id)
  const [activePod, setActivePod] = useState(pods[0])

  const settings = {
    autoplay: false,
    dots: false,
    speed: 700,
    rtl: lang === 'ar',
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => setActivePod(pods.find(pod => pod.id === active)), [active])

  return (
    <React.Fragment>
      <h2 className="font-bold mb-8 sm:fs-40 text-center text-2xl">
        {lang === 'en' ? activePod.name : activePod.arabic_name}
      </h2>
      <div className="text-center">
        <div className="slick--homepods-landing">
          <div className="flex flex-wrap justify-center -mx-4 text-xl">
            {
              pods.map(pod => (
                <div key={pod.id} className="mb-6 px-4 sm:w-1/6 w-full">
                  <button className={`tab-homepods-landing outline-none ${active === pod.id && 'active'}`} onClick={() => setActive(pod.id)}>
                    <img alt={lang === 'en' ? pod.hero_image_alt : pod.arabic_hero_image_alt} className="mb-8 mx-auto" src={pod.hero_image.url} title={lang === 'en' ? pod.page_title : pod.arabic_page_title} />
                    <p>{lang === 'en' ? pod.name : pod.arabic_name}</p>
                  </button>
                </div>
              ))
            }
          </div>
          <Suspense fallback={<div></div>}>
            <Slider {...settings}>
              {
                activePod?.pod_type_images?.map(pod_type_image => (
                  <div key={pod_type_image.id} className="py-12 relative rounded-xl">
                    { pod_type_image.file_type.includes('image')
                      && <img className="sm:h-500px w-full object-cover" alt={lang === 'en' ? pod_type_image.image_alt : pod_type_image.arabic_image_alt} src={pod_type_image.image.url} title={lang === 'en' ? activePod.page_title : activePod.arabic_page_title} />
                      || <video className="h-500px w-full" controls>
                          <source src={pod_type_image.image.url} />
                        </video>
                    }
                    <a className="btn btn--primary absolute left-1/2 -mt-7 transform -translate-x-1/2 whitespace-nowrap" href={`/${lang}/pod_types/${activePod.id}-${activePod.slug}`}>{I18n.t('homepods.dashboard.index.select_and_continue')}</a>
                  </div>
                ))
              }
            </Slider>
          </Suspense>
          <div className="preview sm:preview-fs-24">
            <Suspense fallback={<div></div>}>
              <Description description={lang === 'en' ? activePod.description : activePod.arabic_description} />
            </Suspense>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
